import { gql } from '@apollo/client'

export default gql`
  query PaymentsTopUps {
    paymentsTopUp {
      data {
        attributes {
          principalBanner {
            image {
              data {
                attributes {
                  url
                }
              }
            }
            imageMobile {
              data {
                attributes {
                  url
                }
              }
            }
            href
            newTab
            releaseDate
            endDate
            aspectRatioDesktop
            aspectRatioMobile
            description
          }
          rechargeCard {
            name
            label
            icon {
              data {
                attributes {
                  url
                }
              }
            }
            index
            circleColor
            options {
              label
              icon {
                data {
                  attributes {
                    url
                  }
                }
              }
              circleColor
              slug
            }
            slug
            isActive
            description
            collectionId
            hasSearchEngine
            description
          }
          collectionCard {
            name
            categoryId
            icon {
              data {
                attributes {
                  url
                }
              }
            }
            label
            circleColor
            collectionServices {
              eanCode
              billerCode
              name
              label
              productCode
              productDescription
              categoryId
              minValue
              maxValue
              partialPayment
              collectionType
              modality
              onlyPayment
              specialBill
              isActive
            }
            hasSearchEngine
            isActive
            slug
            description
            placeholder
          }
          topupsRecargas
          topupsPaquetes
          topupsDirecTv
          searchHome {
            eanCode
            label
            slug
            isActive
          }
        }
      }
    }
  }
`

export const HomeTopUpQuery = gql`
  query PaymentsTopUps {
    paymentsTopUp {
      data {
        attributes {
          collectionCard {
            slug
          }
        }
      }
    }
  }
`

export const InactivityScreenQuery = gql`
  query PaymentsTopUps {
    paymentsTopUp {
      data {
        attributes {
          inactivityScreen
        }
      }
    }
  }
`

export const CollectionIdQuery = gql`
  query PaymentsTopUps {
    paymentsTopUp {
      data {
        attributes {
          collectionCard {
            name
            categoryId
            icon {
              data {
                attributes {
                  url
                }
              }
            }
            label
            circleColor
            collectionServices {
              eanCode
              billerCode
              name
              label
              productCode
              productDescription
              categoryId
              minValue
              maxValue
              partialPayment
              collectionType
              modality
              onlyPayment
              specialBill
              isActive
            }
            hasSearchEngine
            isActive
            slug
            description
            placeholder
          }
        }
      }
    }
  }
`
